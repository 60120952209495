const ASYNC_FETCH_CRYPTO_DATA = 'ASYNC_FETCH_CRYPTO_DATA';
const FETCH_CRYPTO_DATA_PENDING = 'FETCH_CRYPTO_DATA_PENDING';
const FETCH_CRYPTO_SUCCESS = 'FETCH_CRYPTO_SUCCESS';
const FETCH_CRYPTO_FAILED = 'FETCH_CRYPTO_FAILED';
const GET_COIN_DATA = 'GET_COIN_DATA';
const GET_CHART = 'GET_CHART';
const SET_COIN = 'SET_COIN';
const SET_CHART = 'SET_CHART';
const SET_COINS = 'SET_COINS';
const SET_MODAL = 'SET_MODAL';

export {
  ASYNC_FETCH_CRYPTO_DATA,
  FETCH_CRYPTO_DATA_PENDING,
  FETCH_CRYPTO_SUCCESS,
  FETCH_CRYPTO_FAILED,
  GET_CHART,
  GET_COIN_DATA,
  SET_COIN,
  SET_CHART,
  SET_COINS,
  SET_MODAL,
};
